import React from "react"
import "./LocalityFilter.scss"

const LocalityFilter = ({ localities, selectedLocality, onLocalityChange, totalCount }) => {
  // Split localities into 3 columns
  
  // Filter out localities that have count as 0
  const filteredLocalities = localities.filter(locality => locality.count > 0)
  //Get the average of count
  const averageCount = filteredLocalities.reduce((total, locality) => total + locality.count, 0) / filteredLocalities.length
  //Create a popular localities array where teh count is greater than the average count
  const popularLocalities = filteredLocalities.filter(locality => locality.count >= averageCount)
  //Create a remaining localities array where the count is less than or equal to the average count
  const remainingLocalities = filteredLocalities.filter(locality => locality.count < averageCount)
  //Create popular locality columns
  const popularLocalitiesPerColumn = Math.ceil(popularLocalities.length / 3)
  const popularLocalityColumns = [
    popularLocalities.slice(0, popularLocalitiesPerColumn),
    popularLocalities.slice(popularLocalitiesPerColumn, popularLocalitiesPerColumn * 2),
    popularLocalities.slice(popularLocalitiesPerColumn * 2)
  ]
   //Create remaining locality columns
   const remainingLocalitiesPerColumn = Math.ceil(remainingLocalities.length / 3)
  const remainingLocalityColumns = [
    remainingLocalities.slice(0, remainingLocalitiesPerColumn),
    remainingLocalities.slice(remainingLocalitiesPerColumn, remainingLocalitiesPerColumn * 2),
    remainingLocalities.slice(remainingLocalitiesPerColumn * 2)
  ]


  const handleChange = (value) => {
    onLocalityChange(value)
  }

  return (
    <div className="locality-filter">
      <div className="locality-filter__header">
      <label className="locality-filter__item locality-filter__all">
        <input
          type="radio"
          name="locality"
          value="All"
          checked={selectedLocality === "All"}
          onChange={() => handleChange("All")}
        />
        <span className="locality-filter__label">All Localities ({totalCount} Options)</span>
      </label>
      </div>
      <h3>Popular Localities</h3>
      <div className="locality-filter__columns">
        {popularLocalityColumns.map((column, columnIndex) => (
          <div key={columnIndex} className="locality-filter__column">
            {column.map((locality) => (
              <label key={locality.value} className="locality-filter__item">
                <input
                  type="radio"
                  name="locality"
                  value={locality.value}
                  checked={selectedLocality === locality.value}
                  onChange={() => handleChange(locality.value)}
                />
                <span className="locality-filter__label">{locality.label + " (" + locality.count + " Options)"}</span>
              </label>
            ))}
          </div>
        ))}
      </div>
      <h3>Other Localities</h3>
      <div className="locality-filter__columns">
        
        {remainingLocalityColumns.map((column, columnIndex) => (
          <div key={columnIndex} className="locality-filter__column">
            {column.map((locality) => (
              <label key={locality.value} className="locality-filter__item">
                <input
                  type="radio"
                  name="locality"
                  value={locality.value}
                  checked={selectedLocality === locality.value}
                  onChange={() => handleChange(locality.value)}
                />
                <span className="locality-filter__label">{locality.label + " (" + locality.count + " Options)"}</span>
              </label>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default LocalityFilter
